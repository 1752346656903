import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links3.css'

const NavigationLinks3 = (props) => {
  return (
    <nav className={`navigation-links3-nav ${props.rootClassName} `}>
      <a href="#about-me" className="navigation-links3-link">
        {props.text}
      </a>
      <a href="#research" className="navigation-links3-link1">
        {props.text1}
      </a>
      <a href="#experience" className="navigation-links3-link2">
        {props.text2}
      </a>
    </nav>
  )
}

NavigationLinks3.defaultProps = {
  text2: 'Experience',
  text3: 'Founding',
  text4: 'Blog',
  rootClassName: '',
  text31: 'Founding',
  text1: 'Research',
  text: 'About',
}

NavigationLinks3.propTypes = {
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  text31: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
}

export default NavigationLinks3
