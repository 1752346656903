import React from 'react'

import { Helmet } from 'react-helmet'

import NavigationLinks3 from '../components/navigation-links3'
import GalleryCard3 from '../components/gallery-card3'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Sope Eweje</title>
        <meta property="og:title" content="Sope Eweje" />
      </Helmet>
      <header data-role="Header" className="home-header">
        <div className="home-nav">
          <NavigationLinks3 rootClassName="rootClassName15"></NavigationLinks3>
        </div>
        <div data-role="BurgerMenu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-nav1">
            <div className="home-container01">
              <img
                alt="image"
                src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                className="home-image"
              />
              <div
                data-role="CloseMobileMenu"
                className="home-close-mobile-menu"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks3 rootClassName="rootClassName16"></NavigationLinks3>
          </div>
          <div>
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <div className="home-about-me">
        <div className="home-summary">
          <h1 className="home-text">Sope Eweje</h1>
          <span className="home-text01">
            <span>
              <span>Improving health with technological innovation</span>
            </span>
          </span>
          <span className="home-text04">
            I am an aspiring physician-innovator with an interest in technology
            for healthcare and life sciences applications. I&apos;m very
            passionate about innovation that enables meaningful, cost-effective
            improvements in healthcare delivery and patient outcomes, while
            scaling to the patients who stand to benefit the most. In the future
            I aim to integrate my experiences in engineering, medicine, and
            business into a career directly serving patients and developing
            medical technologies in parallel.
          </span>
          <div className="home-social-bar">
            <a
              href="https://www.linkedin.com/in/sope-eweje/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
                <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
              </svg>
            </a>
            <a
              href="mailto:feyisope.eweje@pennmedicine.upenn.edu"
              className="home-link01"
            >
              <svg viewBox="0 0 1024 1024" className="home-icon12">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </a>
            <a
              href="https://github.com/sopeeweje"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link02"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon14">
                <path d="M438.857 73.143c242.286 0 438.857 196.571 438.857 438.857 0 193.714-125.714 358.286-300 416.571-22.286 4-30.286-9.714-30.286-21.143 0-14.286 0.571-61.714 0.571-120.571 0-41.143-13.714-67.429-29.714-81.143 97.714-10.857 200.571-48 200.571-216.571 0-48-17.143-86.857-45.143-117.714 4.571-11.429 19.429-56-4.571-116.571-36.571-11.429-120.571 45.143-120.571 45.143-34.857-9.714-72.571-14.857-109.714-14.857s-74.857 5.143-109.714 14.857c0 0-84-56.571-120.571-45.143-24 60.571-9.143 105.143-4.571 116.571-28 30.857-45.143 69.714-45.143 117.714 0 168 102.286 205.714 200 216.571-12.571 11.429-24 30.857-28 58.857-25.143 11.429-89.143 30.857-127.429-36.571-24-41.714-67.429-45.143-67.429-45.143-42.857-0.571-2.857 26.857-2.857 26.857 28.571 13.143 48.571 64 48.571 64 25.714 78.286 148 52 148 52 0 36.571 0.571 70.857 0.571 81.714 0 11.429-8 25.143-30.286 21.143-174.286-58.286-300-222.857-300-416.571 0-242.286 196.571-438.857 438.857-438.857zM166.286 703.429c1.143-2.286-0.571-5.143-4-6.857-3.429-1.143-6.286-0.571-7.429 1.143-1.143 2.286 0.571 5.143 4 6.857 2.857 1.714 6.286 1.143 7.429-1.143zM184 722.857c2.286-1.714 1.714-5.714-1.143-9.143-2.857-2.857-6.857-4-9.143-1.714-2.286 1.714-1.714 5.714 1.143 9.143 2.857 2.857 6.857 4 9.143 1.714zM201.143 748.571c2.857-2.286 2.857-6.857 0-10.857-2.286-4-6.857-5.714-9.714-3.429-2.857 1.714-2.857 6.286 0 10.286s7.429 5.714 9.714 4zM225.143 772.571c2.286-2.286 1.143-7.429-2.286-10.857-4-4-9.143-4.571-11.429-1.714-2.857 2.286-1.714 7.429 2.286 10.857 4 4 9.143 4.571 11.429 1.714zM257.714 786.857c1.143-3.429-2.286-7.429-7.429-9.143-4.571-1.143-9.714 0.571-10.857 4s2.286 7.429 7.429 8.571c4.571 1.714 9.714 0 10.857-3.429zM293.714 789.714c0-4-4.571-6.857-9.714-6.286-5.143 0-9.143 2.857-9.143 6.286 0 4 4 6.857 9.714 6.286 5.143 0 9.143-2.857 9.143-6.286zM326.857 784c-0.571-3.429-5.143-5.714-10.286-5.143-5.143 1.143-8.571 4.571-8 8.571 0.571 3.429 5.143 5.714 10.286 4.571s8.571-4.571 8-8z"></path>
              </svg>
            </a>
            <a
              href="https://scholar.google.com/citations?user=xjyF0SgAAAAJ&amp;hl=en"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link03"
            >
              <svg
                viewBox="0 0 1316.5714285714284 1024"
                className="home-icon16"
              >
                <path d="M1013.714 477.714l10.286 180.571c4.571 80.571-164 146.286-365.714 146.286s-370.286-65.714-365.714-146.286l10.286-180.571 328 103.429c9.143 2.857 18.286 4 27.429 4s18.286-1.143 27.429-4zM1316.571 292.571c0 8-5.143 14.857-12.571 17.714l-640 201.143c-2.286 0.571-4 0.571-5.714 0.571s-3.429 0-5.714-0.571l-372.571-117.714c-32.571 25.714-55.429 88.571-60 165.714 21.714 12.571 36 35.429 36 62.286 0 25.714-13.143 48-33.143 61.143l33.143 247.429c0.571 5.143-1.143 10.286-4.571 14.286s-8.571 6.286-13.714 6.286h-109.714c-5.143 0-10.286-2.286-13.714-6.286s-5.143-9.143-4.571-14.286l33.143-247.429c-20-13.143-33.143-35.429-33.143-61.143 0-27.429 15.429-50.857 37.143-63.429 3.429-66.857 20.571-138.857 56-188.571l-190.286-59.429c-7.429-2.857-12.571-9.714-12.571-17.714s5.143-14.857 12.571-17.714l640-201.143c2.286-0.571 4-0.571 5.714-0.571s3.429 0 5.714 0.571l640 201.143c7.429 2.857 12.571 9.714 12.571 17.714z"></path>
              </svg>
            </a>
            <a
              href="https://twitter.com/sopeeweje"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link04"
            >
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon18">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </a>
          </div>
        </div>
        <img
          alt="image"
          src="/headshot-1200w%20(smaller)-1600h.jpg"
          className="home-image01"
        />
      </div>
      <div className="home-research">
        <div className="home-heading">
          <h1 id="about-me">About Me</h1>
        </div>
        <span className="home-text06">
          <span className="home-text07">
            I&apos;m currently a dual-degree student between the MD program at
            the University of Pennsylvania and the MBA program at Stanford
            University. I am a first-generation Nigerian-American and North
            Carolina native, where I grew up with my parents and identical twin
            brother. With a bias towards meaningful creation, I was lucky enough
            to study mechanical engineering at MIT with a focus on medical
            device development. I spent most of my undergrad years working on
            drug delivery devices in the Langer Lab, working with a group
            creating novel methods for minimally invasive drug delivery via the
            GI tract (and the time I wasn&apos;t in lab was mostly spent on the
            track high jumping with the track &amp; field team).
          </span>
          <br></br>
          <br></br>
          <span>
            A wise piece of advice I received during an internship in 2017 to
            &quot;learn something about AI&quot; colored the next phase of my
            journey, starting by exploring data science and machine learning
            coursework. After leaving MIT, I started medical school at Penn as a
            21st Century Scholar and began working with the
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://sites.brown.edu/airadiology/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link05"
          >
            Radiology AI Lab
          </a>
          <span>
            {' '}
            to conduct applied AI studies for cancer diagnostics, including an
            RSNA-funded work developing a model for the classification of bone
            tumors on MRI (
          </span>
          <a
            href="https://github.com/sopeeweje/Bone-MRI"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link06"
          >
            code
          </a>
          <span>
            ,
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://www.thelancet.com/journals/ebiom/article/PIIS2352-3964(21)00195-X/fulltext"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link07"
          >
            paper
          </a>
          <span>
            ). When the COVID pandemic struck, I was part of a student team
            working on
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://covid-multivent.github.io/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link08"
          >
            an emergency solution
          </a>
          <span>
            {' '}
            with the health system&apos;s Ventilator Rapid Response Team while
            also developing a mobile-based information distribution platform
            that eventually became
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://chiin.org/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link09"
          >
            CHIIN
          </a>
          <span>
            , a non-profit bring medical reference information to Nigerian
            community health workers at the point of care.
          </span>
          <br></br>
          <br></br>
          <span>
            To broaden my perspective on the implementation of healthcare
            innovation, I decided to take a leave from medical school to attend
            business school at Stanford with the support of a scholarship from
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://www.samvid.ventures/samvid-scholars/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link10"
          >
            Samvid Ventures
          </a>
          <span>
            . These days I&apos;m working on something new at the intersection
            of AI and cancer biology.
          </span>
          <br></br>
          <br></br>
          <span>
            Outside of work I&apos;m a big sports fan - playing basketball and
            watching pretty much anything!
          </span>
          <br></br>
        </span>
      </div>
      <div className="home-separator"></div>
      <div className="home-research1">
        <div className="home-heading1">
          <h1 id="research">Research</h1>
        </div>
        <div className="home-container02">
          <h2 className="home-text25">AI / ML</h2>
          <div className="home-paper">
            <div className="home-container03">
              <img alt="image" src="/landig.svg" className="home-image02" />
            </div>
            <div className="home-container04">
              <div className="home-container05">
                <h3>
                  Prognostication of patients with COVID-19 using artificial
                  intelligence based on chest x-rays and clinical data: a
                  retrospective study 
                </h3>
                <p className="home-text27">
                  <span>
                    Z Jiao, JW Choi, K Halsey, TML Tran, B Hsieh, D Wang,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text29">F Eweje</span>
                  <span> et al.</span>
                </p>
                <a
                  href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(21)00039-X/fulltext"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link11"
                >
                  <p className="home-text31">
                    The Lancet Digital Health, May 2021
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="home-paper1">
            <div className="home-container06">
              <img alt="image" src="/ebiom.svg" className="home-image03" />
            </div>
            <div className="home-container07">
              <div className="home-container08">
                <h3>
                  Deep learning for classification of bone lesions on routine
                  MRI
                </h3>
                <p className="home-text33">
                  <span className="home-text34">F Eweje</span>
                  <span>, B Bao, J Wu et al.</span>
                </p>
                <a
                  href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(21)00039-X/fulltext"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link12"
                >
                  <p className="home-text36">eBioMedicine, June 2021</p>
                </a>
              </div>
            </div>
          </div>
          <div className="home-paper2">
            <div className="home-container09">
              <img
                alt="image"
                src="/jama-network-200h.png"
                className="home-image04"
              />
            </div>
            <div className="home-container10">
              <div className="home-container11">
                <h3>
                  Translatability Analysis of National Institutes of
                  Health–Funded Biomedical Research That Applies Artificial
                  Intelligence
                </h3>
                <p className="home-text38">
                  <span className="home-text39">F Eweje</span>
                  <span>, S Byun, R Chandra, F Hu et al.</span>
                </p>
                <a
                  href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(21)00039-X/fulltext"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link13"
                >
                  <p className="home-text41">JAMA Network Open, January 2022</p>
                </a>
              </div>
            </div>
          </div>
          <div className="home-paper3">
            <div className="home-container12">
              <img
                alt="image"
                src="/npj%20digital%20medicine-200h.jpeg"
                className="home-image05"
              />
            </div>
            <div className="home-container13">
              <div className="home-container14">
                <h3>
                  An automated COVID-19 triage pipeline using artificial
                  intelligence based on chest radiographs and clinical data
                </h3>
                <p className="home-text43">
                  <span>
                    CK Kim, JW Choi, Z Jiao, D Wang, J Wu, TY Yi, KC Halsey,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text45">
                    F Eweje
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>et al.</span>
                </p>
                <a
                  href="https://www.nature.com/articles/s41746-021-00546-w"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link14"
                >
                  <p className="home-text47">
                    npj Digital Medicine, January 2022
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container15">
          <h2 className="home-text48">Medical Devices</h2>
          <div className="home-paper4">
            <div className="home-container16">
              <img alt="image" src="/stm-200h.jpeg" className="home-image06" />
            </div>
            <div className="home-container17">
              <div className="home-container18">
                <h3>
                  A gastric resident drug delivery system for prolonged
                  gram-level dosing of tuberculosis treatment
                </h3>
                <p className="home-text50">
                  <span>
                    M Verma, K Vishwanath,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text52">F Eweje</span>
                  <span className="home-text53">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>et al.</span>
                </p>
                <a
                  href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(21)00039-X/fulltext"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link15"
                >
                  <p className="home-text55">
                    Science Translational Medicine, March 2019
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="home-paper5">
            <div className="home-container19">
              <img
                alt="image"
                src="/transplantint-e1613035461864-200h.jpg"
                className="home-image07"
              />
            </div>
            <div className="home-container20">
              <div className="home-container21">
                <h3>
                  Early detection of SARS‐CoV‐2 and other infections in solid
                  organ transplant recipients and household members using
                  wearable devices
                </h3>
                <p className="home-text57">
                  <span>
                    B Keating, E Mukhtar, E Elftmann,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text59">F Eweje</span>
                  <span> et al.</span>
                </p>
                <a
                  href="https://onlinelibrary.wiley.com/doi/abs/10.1111/tri.13860"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link16"
                >
                  <p className="home-text61">
                    Transplant International, June 2021
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="home-paper6">
            <div className="home-container22">
              <img
                alt="image"
                src="/screenshot%202023-09-19%20at%2012.21.41%20am-300w.png"
                className="home-image08"
              />
            </div>
            <div className="home-container23">
              <div className="home-container24">
                <h3>
                  Willingness to use a wearable device capable of detecting and
                  reversing overdose among people who use opioids in
                  Philadelphia
                </h3>
                <p className="home-text63">
                  <span>
                    K Kanter, R Gallagher,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text65">F Eweje</span>
                  <span> et al</span>
                  <span>.</span>
                </p>
                <a
                  href="https://harmreductionjournal.biomedcentral.com/articles/10.1186/s12954-021-00522-3"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link17"
                >
                  <p className="home-text68">
                    Harm Reduction Journal, December 2021
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-separator1"></div>
      <div className="home-research2">
        <div className="home-heading2">
          <h1 id="experience">Experience</h1>
        </div>
        <h2 className="home-text70">Founding</h2>
        <div className="home-container25">
          <div className="home-container26">
            <div className="home-feature-card">
              <img
                alt="image"
                src="/chiin%20logo%20%5Bfull%20text%2C%20alternate%5D-200h.png"
                className="home-image09"
              />
              <h2 className="home-text71">Co-Founder, Managing Director</h2>
              <span className="home-text72">
                A non-profit empowering African community health workers with a
                mobile-based medical reference tool
              </span>
              <ul className="home-ul list">
                <li className="list-item">
                  <span>Wharton Startup Challenge Social Impact Prize</span>
                </li>
                <li className="list-item">
                  <span>Harvard iLab Social Impact Fellowship Award</span>
                </li>
                <li className="list-item">
                  <span>MIT Ideas Social Innovation Award</span>
                </li>
              </ul>
              <a
                href="https://chiin.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link18"
              >
                SEE MORE
              </a>
            </div>
            <div className="home-feature-card1">
              <img
                alt="image"
                src="/disati%20medical%20logo-200h.png"
                className="home-image10"
              />
              <h2 className="home-text76">Co-Founder, CTO</h2>
              <span className="home-text77">
                A respiratory monitoring medical device startup working to take
                the guesswork out of respiratory care with breathing effort
                measurement technology.
              </span>
              <ul className="home-ul1 list">
                <li className="list-item">
                  <span>
                    <a
                      href="https://patents.google.com/patent/US11679217B2/en"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link19"
                    >
                      Patented
                    </a>
                    <span> non-invasive monitoring technology</span>
                  </span>
                </li>
                <li className="list-item">
                  <span>
                    <span>MGH Springboard Studio </span>
                    <a
                      href="https://twitter.com/MGHSpringboard/status/1472962609087459330?lang=en"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link20"
                    >
                      iSolve award winner
                    </a>
                  </span>
                </li>
                <li className="list-item">
                  <span>
                    <span>
                      FDA-sponsored
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <a
                      href="https://www.chop.edu/news/chop-based-pennsylvania-pediatric-medical-device-consortium-awards-seed-grants-five-companies"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="home-link21"
                    >
                      Pennsylvania Pediatric Medical Device Consortium
                    </a>
                    <span> seed grant</span>
                  </span>
                </li>
              </ul>
              <a
                href="https://www.linkedin.com/company/disati-medical/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link22"
              >
                SEE MORE
              </a>
            </div>
          </div>
        </div>
        <h2 className="home-text85">Investing</h2>
        <div className="home-container27">
          <div className="home-feature-card2">
            <img alt="image" src="/fusion-900w.png" className="home-image11" />
            <h2 className="home-text86">Fusion Fund</h2>
            <span className="home-text87">
              Early-stage healthcare deep tech investing, across medical
              devices, biotech, diagnostics, AI and more.
            </span>
            <a
              href="https://www.fusionfund.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link23"
            >
              SEE MORE
            </a>
          </div>
          <div className="home-feature-card3">
            <img
              alt="image"
              src="/bx_logo_jpeg-900w.jpg"
              className="home-image12"
            />
            <h2 className="home-text88">Blackstone Life Sciences</h2>
            <span className="home-text89">
              Pharma, Biotech, and MedTech investing with Blackstone Life
              Sciences
            </span>
            <a
              href="https://www.blackstone.com/our-businesses/life-sciences/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link24"
            >
              SEE MORE
            </a>
          </div>
        </div>
      </div>
      <div className="home-separator2"></div>
      <div className="home-gallery">
        <div className="home-container28">
          <GalleryCard3
            title="Stanford v Cal"
            subtitle="Football"
            image_src="/img_6296-1500h.jpg"
            rootClassName="rootClassName"
          ></GalleryCard3>
          <GalleryCard3
            title="Stanford v Gonzaga"
            subtitle="WBB"
            image_src="/img_56324a345a81-1-1500w.jpeg"
            rootClassName="rootClassName1"
          ></GalleryCard3>
          <GalleryCard3
            title="Tokyo Swallows v Hiroshima Toyo Carp"
            image_src="/img_8278-1500h.jpg"
            rootClassName="rootClassName3"
            subtitle="NPB"
          ></GalleryCard3>
          <GalleryCard3
            title="Pelicans v Trailblazers"
            subtitle="NBA"
            image_src="/img_1409-1500w.jpeg"
            rootClassName="rootClassName2"
          ></GalleryCard3>
          <GalleryCard3
            image_src="/img_6801-1500h.jpg"
            rootClassName="rootClassName4"
            title="Kansas St v UCLA"
            subtitle="NCAA tournament"
          ></GalleryCard3>
          <GalleryCard3
            image_src="/img_0604-1500h.jpeg"
            rootClassName="rootClassName6"
            title="Panthers v Saints"
            subtitle="NFL"
          ></GalleryCard3>
          <GalleryCard3
            image_src="/img_7759-1500h.jpg"
            rootClassName="rootClassName5"
            title="Latvia v Brazil"
            subtitle="FIBA world cup"
          ></GalleryCard3>
          <GalleryCard3
            image_src="/img_7343-1500h.jpg"
            rootClassName="rootClassName7"
            title="CF Montréal v NYCFC"
            subtitle="MLS"
          ></GalleryCard3>
        </div>
      </div>
      <footer className="home-footer">
        <span className="home-text90">© 2023 Feyisope Eweje</span>
      </footer>
    </div>
  )
}

export default Home
