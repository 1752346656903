import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
        <div>
          <Route component={Home} exact path="/" />
        </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
